import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: "Helvetica",
    color: "#000",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 20,
  },
  titleContainer: {
    backgroundColor: "#f0f0f0",
    paddingVertical: 10,
    textAlign: "center",
    width: "100%",
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 20,
    width: "100%",
  },
  addressSection: {
    flexBasis: "48%",
  },
  tableHeader: {
    marginTop: 20,
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: "bold",
    columnGap: 80,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    borderBottom: "1px solid #ccc",
  },
  tableCol: {
    width: "25%",
    textAlign: "right",
  },
  total: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "right",
    marginTop: 20,
  },
  totalgst: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
    marginTop: 20,
  },
  footer: {
    marginTop: 30,
    fontSize: 12,
    textAlign: "center",
  },
  note: {
    marginTop: 10,
    fontStyle: "italic",
  },
});

// Invoice Document
const InvoiceDocument = ({ data }) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Text>Invoice No. {data.invoiceNo}</Text>
        </View>

        {/* Title with Background */}
        <View style={styles.titleContainer}>
          <Text style={styles.title}>INVOICE</Text>
        </View>

        {/* Addresses and Date Section */}
        <View style={styles.section}>
          <View style={styles.addressSection}>
            <Text>Date: {data.date}</Text>
            <Text style={{ marginTop: "5px" }}>Billed to: {data.billTo.name}</Text>
            <Text style={{ marginTop: "5px" }}>{data.billTo.address}</Text>
            <Text style={{ marginTop: "5px" }}>{data.billTo.email}</Text>
            <Text style={{ marginTop: "5px" }}>Mobile no. {data.billTo.mobile}</Text>
          </View>
          <View style={styles.addressSection}>
            <Text>From: {data.from.name}</Text>
            <Text style={{ marginTop: "5px" }}>{data.from.address}</Text>
            <Text style={{ marginTop: "5px" }}>{data.from.email}</Text>
            <Text style={{ marginTop: "5px" }}>Mobile no. {data.from.mobile}</Text>
          </View>
        </View>

        {/* Table Header */}
        <View style={styles.tableHeader}>
          <Text style={styles.tableCol}>Item</Text>
          <Text style={styles.tableCol}>Quantity</Text>
          <Text style={styles.tableCol}>Price</Text>
          <Text style={styles.tableCol}>Amount</Text>
        </View>

        {/* Table Rows */}
        {data.items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCol}>{item.name}</Text>
            <Text style={styles.tableCol}>{item.quantity}</Text>
            <Text style={styles.tableCol}>Rs.{item.price}</Text>
            <Text style={styles.tableCol}>Rs.{item.amount}</Text>
          </View>
        ))}

        {/* Total Section */}
        <Text style={styles.totalgst}>
           GST (18%) : Rs.{data.items[0].gst}
        </Text>
        <Text style={styles.total}>
          Total (incl. GST): Rs.{data.items[0].totalAmount}
        </Text>

        {/* Footer Section */}
        <View style={styles.footer}>
          <Text>Payment method: {data.paymentMethod}</Text>
          <Text style={styles.note}>Note: {data.note}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceDocument;
