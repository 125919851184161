
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';

import './edit.css';
import Layout from '../../layout';

function EditUser() {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        mobile_number: '',
        email: '',
        password: '',
        confirm_password: '',
        amount: '',
        additional_info: ''
    });

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });

    const [amountError, setAmountError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [loading, setLoading] = useState(false); // State for loading spinner

    useEffect(() => {
        console.log("User ID:", userId);
        axios.get(`https://api.sylfaen.in/api/v1/register/${userId}`)
            .then(response => {
                if (response.data.success) {
                    setFormData(response.data.data);
                } else {
                    console.error('Failed to fetch user data');
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'name') {
            const filteredValue = value.replace(/[0-9]/g, '');
            setFormData(prevState => ({
                ...prevState,
                [name]: filteredValue,
            }));
        } else if (name === 'mobile_number') {
            const filteredValue = value.replace(/[^0-9]/g, '').slice(0, 10);
            setFormData(prevState => ({
                ...prevState,
                [name]: filteredValue,
            }));
            setMobileError('');
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }

        if (name === 'amount') {
            if (value && value < 500) {
                setAmountError('Amount must be at least 500 INR.');
            } else {
                setAmountError('');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userId) {
            console.error("No user ID provided");
            return;
        }

        if (formData.mobile_number.length !== 10) {
            setMobileError('Mobile number must be exactly 10 digits.');
            return;
        }

        if (formData.amount < 500) {
            setAmountError('Amount must be at least 500 INR.');
            return;
        }

        setLoading(true); // Start loading spinner

        try {
            const updatedData = { ...formData };
            delete updatedData._id;

            const response = await axios.post(` https://api.sylfaen.in/api/v1/register/edit/${userId}`, updatedData);
            if (response.data.success) {
                toast.success('User updated successfully!');
                setTimeout(() => {
                    navigate('/admin/dashboard');
                }, 1000);
            } else {
                toast.error('Update failed.');
            }
        } catch (error) {
            console.error('There was an error updating the user!', error);
            toast.error('Update failed.');
        } finally {
            setLoading(false); // Stop loading spinner
        }
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    return (
        <Layout>
             <h3>Edit User</h3>
            <div className=" col-lg-6 col-md-12 form-container-edit">
                <div className="form-wrapper">
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: "flex", justifyContent: "space-between" }} className='row'>
                            <div className="form-group col-6">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Enter your name"
                                    onChange={handleChange}
                                    value={formData.name}
                                    required
                                />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="mobile_number">Mobile</label>
                                <input
                                    type="text"
                                    id="mobile_number"
                                    name="mobile_number"
                                    placeholder="Enter your mobile number"
                                    onChange={handleChange}
                                    value={formData.mobile_number}
                                    required
                                />
                                {mobileError && <div className="error-message">{mobileError}</div>}
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    onChange={handleChange}
                                    value={formData.email}
                                    required
                                />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="password">Create Password</label>
                                <div className="password-container">
                                    <input
                                        type={showPassword.password ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        placeholder="Create password"
                                        onChange={handleChange}
                                        value={formData.password}
                                        required
                                    />
                                    <button type="button" onClick={() => togglePasswordVisibility('password')} className="password-toggle-btn">
                                        <FontAwesomeIcon  icon={showPassword.password ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="confirm_password">Confirm Password</label>
                                <div className="password-container">
                                    <input
                                        type={showPassword.confirmPassword ? "text" : "password"}
                                        id="confirm_password"
                                        name="confirm_password"
                                        placeholder="Confirm password"
                                        onChange={handleChange}
                                        value={formData.confirm_password}
                                        required
                                    />
                                    <button type="button" onClick={() => togglePasswordVisibility('confirmPassword')} className="password-toggle-btn">
                                        <FontAwesomeIcon icon={showPassword.confirmPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="amount">Amount</label>
                                <input
                                    type="text"
                                    id="amount"
                                    name="amount"
                                    placeholder="Pay 500 INR Rupees"
                                    onChange={handleChange}
                                    value={formData.amount}
                                    required
                                />
                                {amountError && <div className="error-message">{amountError}</div>}
                            </div>
                            <div className=" form-g col-12">
                                <label>Additional info</label>
                                <textarea
                                    id="additional_info"
                                    name="additional_info"
                                    placeholder="Enter your messages"
                                    rows="3"
                                    onChange={handleChange}
                                    value={formData.additional_info}
                                />
                            </div>
                        </div>
                        <button type="submit" className="submit-btn" disabled={loading}>
                            Update
                        </button>
                    </form>
                </div>
            </div>
            {loading && (
                <div className="overlay">
                    <ClipLoader size={100} color={"#ffffff"} />
                </div>
            )}
            <ToastContainer />
        </Layout>
    );
}

export default EditUser;
